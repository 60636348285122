.section-impressum, .section-datenschutz {
  .section-text {
    font-size: 16px;

    .creator-link {
      margin: 0;
      color: $accent;
      text-decoration: underline; } } }

@media (max-width: 760px) {
  .section-impressum, .section-datenschutz {
    margin-top: 70px; } }
