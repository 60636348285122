.section-wir-bieten-sub-selector {
  .section-inner {
    padding: 10px 0 0 0; } }

.section-wir-bieten-sub-content {
  .section-inner {
    padding: 30px 0 70px 0; } }

.section-wir-bieten-sub {
  .section-inner {
    max-width: 800px; } }

@media (max-width: 900px) {
  .section-wir-bieten-sub {
    .section-inner {
      .section-gewerk {
        height: auto; } } } }

@media (max-width: 760px) {
  .section-wir-bieten-sub-content {
    .section-inner {
      padding-top: 10px; } }

  .section-wir-bieten-sub-selector {
    margin-top: 70px; } }
