.button-primary {
  background: $accent;
  padding: 10px 20px;
  color: $white;
  font-size: 1.2em;

  &:hover {
    background: lighten($accent, 4%);
    color: $white; } }

.button-secondary {
  background: $white;
  padding: 10px 20px;
  color: $accent;
  font-size: 1.2em;

  &:hover {
    background: darken($white, 6%);
    color: $accent; } }

.u-cta-wrapper {
  display: block;
  text-align: center; }

.u-cta {
  display: inline-block;
  margin-top: 30px; }

