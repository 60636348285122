.u-list {
  margin: 10px 0;

  li {
    font-size: 16px;
    color: $black;

    a {
      color: $accent;
      text-decoration: none; } }

  .u-list-li {
    .u-list-li-title {
      line-height: 1;
      font-weight: 600;

      &:after {
        content: '';
        display: block;
        width: 175px;
        height: 1px;
        background: $accent;
        margin: 2px 0 2px 0; } }

    .u-list-li-paragraph {
      font-size: 0.9em;
      line-height: 1.3;
      color: lighten($black, 15%);
      transition: color 100ms ease-in-out;
      margin-bottom: 15px;

      &:hover {
        color: lighten($black, 5%); } } }

  .u-list {
    margin: 0px 0 10px 0;
    padding-left: 30px;

    .u-list-li {
      font-size: 0.9em; } } }

@media (max-width: 760px) {
  .u-list {
    padding-left: 20px; } }
