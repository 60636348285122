body > .container {
  min-height: 100%;
  position: relative;

  .content {
    position: relative;
    z-index: 2;
    padding-bottom: 62px;
    max-width: 100%;
    overflow: hidden; } }

// used to position items in flex layout
.u-blank-spacer {
  width: 0;
  height: 0;
  border: 0;
  padding: 0;
  margin: 0; }
