.u-icon-small {
  width: 22px;
  height: 22px;
  @include sprite-grid-background('/images/icons.png');

  &.u-icon-small-email {
    @include sprite-grid-position(0, 6); }

  &.u-icon-small-phone {
    @include sprite-grid-position(1, 6); }

  &.u-icon-small-fax {
    @include sprite-grid-position(2, 6); }

  &.u-icon-small-website {
    @include sprite-grid-position(3, 6); } }

.u-icon-large {
  width: 44px;
  height: 44px;
  @include sprite-grid-background('/images/icons.png', 2);

  &.u-icon-large-versorgung {
    @include sprite-grid-position(5, 7, 2); }

  &.u-icon-large-elektro {
    @include sprite-grid-position(0, 7, 2); }

  &.u-icon-large-verfahren {
    @include sprite-grid-position(4, 7, 2); }

  &.u-icon-large-klima {
    @include sprite-grid-position(3, 7, 2); }

  &.u-icon-large-zeichner {
    @include sprite-grid-position(1, 7, 2); }

  &.u-icon-large-bau {
    @include sprite-grid-position(2, 7, 2); }

  &.u-icon-large-wasser {
    @include sprite-grid-position(2, 8, 2); }

  &.u-icon-large-gase {
    @include sprite-grid-position(6, 7, 2); }

  &.u-icon-large-sani {
    @include sprite-grid-position(1, 8, 2); }

  &.u-icon-large-feuer {
    @include sprite-grid-position(0, 8, 2); }

  &.u-icon-large-eis {
    @include sprite-grid-position(3, 8, 2); }

  &.u-icon-large-anschluss {
    @include sprite-grid-position(4, 8, 2); } }
