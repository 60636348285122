.section {
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;

  .section-inner {
    max-width: 700px;
    margin: 0 auto;
    padding: 70px 0; }

  .section-title, .section-subtitle {
    margin: 0;
    padding: 0;
    text-transform: uppercase; }

  .section-title {
    margin-bottom: 6px; }

  .section-subtitle {
    font-weight: normal;
    margin-bottom: 30px; }

  .section-subsubtitle {
    font-weight: normal;
    margin-bottom: 5px;
    color: {} }

  .section-text {
    font-size: 18px;
    margin: 0;

    &.section-text-small {
      font-size: 16px; } }

  &.section-bright {
    background: $white;

    .section-title {
      color: $accent; }

    .section-subtitle, .section-subsubtitle {
      color: $black; } }

  &.section-accent {
    background: $bgcolor;

    .section-title {
      color: $accent; }

    .section-subtitle, .section-subsubtitle {
      color: $black; } } }

@media (max-width: 900px) {
  .section {
    padding: 0 20px;

    .section-inner {
      padding: 40px 0; } } }

@media (max-width: 760px) {
  .section {
    .section-text {
      font-size: 16px; }

    .section-title {
      font-size: 3em; }

    .section-subtitle {
      font-size:  1.5em; } } }
