.section-kontakt-standort, .section-partner-standort, .section-job-standort {
  max-width: 800px;
  margin: 0 auto 30px;

  &:last-of-type {
    margin-bottom: 0; }

  display: flex;
  justify-content: center;
  align-items: center;

  .standort-meta {
    flex-grow: 1;
    font-size: 20px;
    line-height: 1.2;

    .standort-meta-with-icon {
      margin: 5px 0;
      .standort-meta-icon, .standort-meta-link, .standort-meta-text {
        display: inline-block; } }

    .standort-meta-email {
      margin-top: 30px; }

    .standort-meta-title {
      font-weight: bold;
      margin-bottom: 20px; }

    .standort-meta-link {
      text-decoration: underline; }

    .standort-meta-icon {
      margin-right: 10px;
      margin-bottom: -4px; } }

  .standort-map, .standort-image {
    width: 400px;
    height: 400px;
    background: transparent; }

  .standort-map {
    cursor: pointer; } }

.section-kontakt-standort {
  .standort-meta {
    .standort-meta-link {
      color: $accent; } } }

.section-partner-standort {
  margin-top: 30px;

  .standort-meta {
    color: $black;
    font-size: 18px;

    .standort-meta-link {
      color: $accent; }

    .standort-meta-phone {
      margin-top: 20px; } } }

.section-job-standort {
  font-size: 1.1em;
  color: $black;
  margin: 40px auto 0 auto;
  width: 300px;

  .standort-meta {
    font-size: inherit; }

  .standort-meta-link {
    color: $accent; } }

@media (max-width: 700px) {
  .section-kontakt-standort, .section-partner-standort, .section-job-standort {
    flex-direction: column;
    margin-bottom: 50px;

    .standort-meta {
      font-size: 16px;

      .standort-meta-title {
        margin-bottom: 10px; }

      .standort-meta-email {
        margin-top: 20px; } }

    .standort-map, .standort-image {
      width: 100%;
      padding-bottom: 100%;
      height: 0px;
      margin: 20px auto 0; } } }


