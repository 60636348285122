.global-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  z-index: 1001;
  background: $black;
  color: $white;
  box-sizing: border-box;
  padding: 20px;
  height: 62px;

  .global-footer-copyright {
    float: left; }

  .global-footer-quicklinks {
    float: right;

    .global-footer-quicklinks-link {
      color: $white;
      margin-left: 10px; } } }

@media (max-width: 760px) {
  .global-footer {
    display: none; }

  body {
    .content {
      padding-bottom: 0px !important; } } }
