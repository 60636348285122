// Cool
$aqua:  #7FDBFF;
$blue:  #0074D9;
$navy:  #001F3F;
$sky:   rgb(156, 208, 255);
$teal:  #39CCCC;
$green: #2ECC40;
$olive: #3D9970;
$lime:  #01FF70;
$peterRiver: #3498db;


// Warm
$yellow:  #FFDC00;
$orange:  #FF851B;
$red:     #FF4136;
$fuchsia: #F012BE;
$purple:  #B10DC9;
$maroon:  #85144B;


// Gray Scale
$white:  #fff;
$silver: #ddd;
$gray:   #aaa;
$gray2:  #5a5959;
$black:  #111;

$smeColor: #800080;

$accent: $smeColor;
$bgcolor: lighten(#e5eaee, 2%);
