.section-referenzen-sub {
  .section-inner {
    padding-bottom: 0; }

  .section-cards {
    padding-bottom: 70px; } }

@media (max-width: 760px) {
  .section-referenzen-sub {
    margin-top: 70px; } }
