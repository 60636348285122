.section-cards {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .section-cards-title {
    color: $accent;
    font: {
      size: 20px;
      style: italic;
      weight: normal; }
    margin: 0;
    width: 100%; }

  .section-card {
    background: $white;
    margin: 8px 30px;
    padding-bottom: 5px;
    max-width: 250px;

    .section-card-image-wrapper {
      position: relative;
      width: 250px;
      height: 250px;

      &:hover .section-card-image-overlay {
        opacity: 1; } }

    .section-card-image {
      width: 250px;
      height: 250px; }

    .section-card-image-overlay {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: opacity 100ms ease-in-out;

      .section-card-image-overlay-line {
        padding: 10px;
        text-align: center;
        font-size: 1.2em; } }

    .section-card-title, .section-card-name, .section-card-meta, .section-card-text {
      font-size: 20px;
      margin: 6px 10px;
      padding: 0;
      line-height: 1; }

    .section-card-title {
      margin-top: 10px; }

    .section-card-title, .section-card-name {
      color: $accent; }

    .section-card-name {
      font-weight: 600;
      margin-bottom: 20px; } } }

@media (max-width: 760px) {
  .section-cards {
    .section-cards-title {
      font-size: 1.3em; }
    .section-card {
      .section-card-title, .section-card-name, .section-card-meta, .section-card-text {
        font-size: 1.3em; } } } }
