.global-header {
  background: {
    image: url(/images/hero-bg.png);
    size: cover; }
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .global-header-headline {
    color: $white;
    margin: 0;
    text-align: center;
    font: {
      size: 54px;
      weight: normal;
      style: italic; } } }

@media (max-width: 900px) {
  .global-header {
    height: 400px;

    .global-header-headline {
      margin-top: 90px;
      font-size: 2em; } } }

@media (max-width: 760px) {
  .global-header {
    height: 300px;
    .global-header-headline {
      font-size: 0em; } } }

@media (max-width: 350px) {
  .global-header {
    height: 200px; } }
