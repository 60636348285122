.u-belt-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;

  .u-belt-selector-item {
    color: $accent;
    padding: 5px;
    margin: 0px 10px 10px 10px;
    text-align: center;
    box-sizing: border-box;
    width: 25%;
    border-bottom: 3px solid transparent;

    &:hover, &.js-active-belt-item {
      border-bottom-color: $accent; }

    .u-belt-selector-item-text {
      font-size: 1em;
      line-height: 1.2;
      margin-top: 5px; }

    .u-belt-selector-item-icon {
      margin: 0 auto; } } }

.u-belt {
  width: 100%;
  position: relative;
  margin: 30px 0 20px;

  .u-belt-item {
    height: auto;
    position: relative;
    z-index: 1;
    display: none;

    &.js-active-belt-item {
      display: block; }

    .u-belt-item-image {
      width: 100%;
      height: 500px;
      background: {
        size: cover;
        position: center; }
      position: relative;
      margin-bottom: 15px;

      .u-belt-item-title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        padding: 0px 15px;
        box-sizing: border-box;

        .u-belt-item-title-inner {
          max-width: 800px;
          margin: 0 auto;
          color: $white;
          font: {
            size: 1.3em;
            weight: 600; }
          padding: 10px;
          box-sizing: border-box; } } }

    .u-belt-item-split {
      display: flex;
      max-width: 800px;
      margin: 0 auto;

      .u-belt-item-split-item {
        margin: 0;
        padding: 0 10px;
        box-sizing: border-box;

        ul {
          padding: 0 10px 0 30px;
          margin: 0;

          li {
            font-size: 1em; } } }

      .u-belt-item-split-3 {
        flex-grow: 3; }

      .u-belt-item-split-2 {
        flex-grow: 2; } }

    .u-belt-item-split-tilte {
      color: $accent;
      font: {
        size: 1.1em;
        weight: 600; } } } }

.u-belt-controls {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  pointer-events: none;
  z-index: 2;

  .u-belt-control {
    width: 30px;
    height: 45px;
    margin-top: -240px;
    cursor: pointer;
    pointer-events: auto;
    @include sprite-grid-background('/images/icons.png', 3);

    &.u-belt-control-prev {
      @include sprite-grid-position(4, 0, 3); }

    &.u-belt-control-next {
      @include sprite-grid-position(5, 0, 3); } }

  .u-belt-control-next, .u-belt-control-prev {
    position: absolute;
    top: calc(694px); }

  .u-belt-control-prev {
    left: 10px; }

  .u-belt-control-next {
    right: 10px; } }

@media (max-width: 900px) {
  .u-belt-controls {
    display: none; } }

@media (max-width: 760px) {
  .u-belt-selector {
    .u-belt-selector-item {
      width: 50%;
      margin: 5px 0; } } }

@media (max-width: 500px) {
  .u-belt-item {
    .u-belt-item-split {
      display: block !important;
      font-size: 1.1em;

      .u-belt-item-split-item {
        width: 100%;
        margin-top: 10px !important;
        padding: 0; } } } }

@media (max-width: 350px) {
  .u-belt-selector {
    .u-belt-selector-item {
      width: 100%; } } }
