a {
  color: $blue;
  text-decoration: none;
  outline: 0;
  box-sizing: border-box;
  cursor: pointer;

  &:hover,
  &:focus {
    color: lighten($blue, 20%); } }

a[href^='tel'] {
  color: $accent;
  text-decoration: underline; }

.u-anchor {
  display: block;
  position: relative;
  top: -110px;
  visibility: hidden; }
