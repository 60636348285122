.section-jobs-sub-selector {
  .section-inner {
    padding: 70px 0 0 0; } }

.section-jobs-sub-content {
  .section-inner {
    padding: 30px 0 70px 0; } }

.section-jobs-sub {
  .section-inner {
    max-width: 800px; }

  .section-jobs-description {
    font-size: 18px; }

  .section-job-summary {
    font-size: 18px; } }

@media (max-width: 900px) {
  .section-jobs-sub {
    .section-inner {
      .section-job {
        height: auto; } } } }

@media (max-width: 760px) {
  .section-jobs-sub {
    .section-jobs-description, .section-job-summary {
      font-size: 16px;
      line-height: 1.3; } }

  .section-jobs-sub-content {
    .section-inner {
      padding-top: 10px; } }

  .section-jobs-sub-description {
    margin-top: 70px; } }
