@mixin sprite-grid-background($url, $scale: 4) {
  background: {
    image: url($url);
    repeat: no-repeat;
    size: (664px / $scale) auto; } } // 664/$scale x 1088/$scale

@mixin sprite-grid-position($col, $row, $scale: 4) {
  $x: ($col * -(96px / $scale));
  $y: ($row * -(96px / $scale));
  background-position: $x $y; }
