html {
  height: 100%; }

body {
  background-color: white;
  font-size: 14px;
  line-height: 1.6;
  font-family: 'Open Sans', sans-serif;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  height: 100%; }

@media (max-width: 500px) {
  body {
    font-size: 12px; } }
