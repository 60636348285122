.section-wir-bieten-keywords {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;

  .section-wir-bieten-keyword {
    box-sizing: border-box;
    flex-basis: 50%;
    flex-grow: 1;
    font: {
      size: 16px;
      weight: 600; }
    color: lighten($black, 15%);
    text-align: left;
    padding: 5px 0; } }

@media (max-width: 500px) {
  .section-wir-bieten-keywords {
    display: block;

    .section-wir-bieten-keyword {
      display block {} } } }
