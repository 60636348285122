.global-navigation {
  position: fixed;
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  height: 110px;
  box-sizing: border-box;
  transition: background 200ms ease-in-out;
  z-index: 1001;

  .global-navigation-logo {
    width: auto;
    height: auto;
    margin: 0 20px 0;

    .global-navigation-logo-image {
      width: 130px;
      height: 66px;
      position: relative;
      @include sprite-grid-background('/images/logos.png', 5.2);
      @include sprite-grid-position(0, 3);
      opacity: 1;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include sprite-grid-background('/images/logos.png', 5.2);
        @include sprite-grid-position(0, 3);
        opacity: 0;
        transition: opacity 200ms ease-in-out; } } }

  .global-navigation-nav {
    margin: 0 0px;

    .global-navigation-nav-link {
      padding: 10px 15px;
      color: $accent;
      font: {
        size: 18px; }
      transition: color 200ms ease-in-out; } }

  &.global-navigation-white, &.global-navigation-purple {
    position: static; }

  &.global-navigation-is-scrolled, &.global-navigation-white {
    background: $white;
    box-shadow: 0 -4px 8px $black;

    .global-navigation-nav-link {
      color: $accent; }

    .global-navigation-logo-image:after {
      opacity: 1; } }

  &.global-navigation-purple {
    background: $accent;

    .global-navigation-nav-link {
      color: $white; }

    .global-navigation-logo-image {
      background-image: url(/images/logo-white.png); } }

  .global-navigation-mobile-toggle {
    display: none;
    position: absolute;
    width: 26px;
    height: 17px;
    top: calc(50% - 9px);
    left: 10px;
    @include sprite-grid-background('/images/icons.png');
    @include sprite-grid-position(0, 9);
    cursor: pointer;
    opacity: 1;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @include sprite-grid-background('/images/icons.png');
      @include sprite-grid-position(0, 9);
      opacity: 0;
      transition: opacity 200ms ease-in-out; } }

  &.global-navigation-is-scrolled, &.global-navigation-white {
    .global-navigation-mobile-toggle:after {
      opacity: 1; } } }

.u-navigation-next-section-white {
  display: block;
  padding: 20px 20px 30px 20px;
  color: $white;
  width: 100px;
  margin: 5px auto;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  @include sprite-grid-background('/images/icons.png');
  @include sprite-grid-position(0, 3); }

.global-navigation-mobile {
  z-index: 1002;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 250px;
  background: $accent;
  box-sizing: border-box;
  padding: 0px 0 20px 0;
  margin-left: -250px;
  color: $white;
  transition: margin 200ms ease-out;

  .global-navigation-mobile-nav {
    .global-navigation-nav-link {
      display: block;
      color: $white;
      padding: 12px 20px;
      font-size: 1.4em;
      line-height: 1;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

    .global-navigation-mobile-logo {
      width: 100px;
      height: 52px;
      @include sprite-grid-background('/images/logos.png', 7);
      @include sprite-grid-position(0, 0);
      border-bottom: 0;
      padding: 0;
      margin: 10px 20px; } }

  .global-navigation-mobile-close {
    position: absolute;
    right: 10px;
    top: 0px;
    line-height: 37px;
    font: {
      size: 40px;
      weight: 300; }
    line-height: 1;
    color: inherit;
    box-sizing: border-box;
    padding: 10px; }

  .global-navigation-mobile-nav-lower {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.8em;

    .global-navigation-nav-link {
      display: inline-block;
      font-size: 1.4em;
      border-bottom: 0;
      padding: 10px; } }

  &.global-navigation-mobile-open {
    margin-left: 0px;

    .global-navigation-mobile-close {
      display: block; } } }

.global-navigation-mobile {
  &.js-navigation-mobile-is-open {
    margin-left: 0; } }

@media (max-width: 900px) {
  .global-navigation {
    height: auto;

    .global-navigation-logo {
      .global-navigation-logo-image, .global-navigation-logo-image:after {
        height: 60px !important;
        width: 110px;
        @include sprite-grid-background('/images/logos.png', 6.2);
        @include sprite-grid-position(0, 2.2); } } }

  .u-navigation-next-section-white {
    transform: scale(0.5);
    padding: 15px; } }

@media (max-width: 760px) {
  .global-navigation {
    padding: 10px 20px;

    &.global-navigation-white, &.global-navigation-purple {
      position: fixed; }

    .global-navigation-logo {
      .global-navigation-logo-image, .global-navigation-logo-image:after {
        height: 52px !important;
        width: 100px;
        @include sprite-grid-background('/images/logos.png', 7);
        @include sprite-grid-position(0, 2.3); } } }

  .global-navigation-mobile-toggle {
    display: block !important; }

  .global-navigation-nav {
    display: none; } }
